<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedAktion || !selectedAktion.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="aktionen" v-model:selection="selectedAktion" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Aktionen" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Aktionen</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="GRUPPE" header="Gruppe" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Gruppe</span>
							{{slotProps.data.GRUPPE}}
						</template>
					</Column>
					<Column field="AKTION" header="Aktion" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Aktion</span>
							{{slotProps.data.AKTION}}
						</template>
					</Column>
					<Column field="bezeichnung" header="Bezeichnung" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Bezeichnung</span>
							{{slotProps.data.bezeichnung}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editAktion(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteAktion(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="aktionDialog" :style="{width: '450px'}" header="Aktion Details" :modal="true" class="p-fluid">
          <div class="field">
            <label for="bezeichnung">Bezeichnung</label>
            <InputText id="bezeichnung" v-model.trim="aktionObj.bezeichnung" required="true" autofocus :class="{'p-invalid': submitted && !aktionObj.bezeichnung}" />
            <small class="p-invalid" v-if="submitted && !aktionObj.bezeichnung">Bezeichnung muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="GRUPPE" class="mb-3">Gruppe</label>
            <AutoComplete id="gruppe" v-model="aktionObj.GRUPPE" :dropdown="true" :suggestions="filteredGruppen" @complete="searchGruppen($event)" placeholder="Gruppe" />
          </div>
          <div class="field">
            <label for="AKTION" class="mb-3">Aktion</label>
            <AutoComplete id="aktion" v-model="aktionObj.AKTION" :dropdown="true" :suggestions="filteredAktionen" @complete="searchAktion($event)" placeholder="Aktion" />
          </div>
          <div class="field">
            <label for="BAUTEIL">Beschreibung</label>
            <Textarea id="bauteil" v-model="aktionObj.BAUTEIL" required="true" rows="3" cols="20" />
          </div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveAktionen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteAktionDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="aktionObj"><b>{{aktionObj.bezeichnung}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteAktionDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteAktionen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteAktionenDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="aktionObj">Ausgewählte Aktionen wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteAktionenDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedAktionen" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
	data() {
		return {
      aktionen: null,
      aktionDialog: false,
			deleteAktionDialog: false,
			deleteAktionenDialog: false,
      neu: false,
      aktionObj: {},
			selectedAktion: null,
      gruppen: [],
      aktion: [],
      filteredGruppen: [],
      filteredAktionen: null,
			filters: {},
			submitted: false
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    let gruppen = await this.dataService.getAktionGruppen();
    gruppen.forEach((el) => {
      this.gruppen.push(el.GRUPPE);
    });
    let aktion = await this.dataService.getAktionAktionen();
    aktion.forEach((el) => {
      this.aktion.push(el.AKTION);
    });

    this.aktionen = await this.dataService.getAktionen();
	},
	methods: {
		openNew() {
			this.aktionObj = {};
			this.submitted = false;
			this.aktionDialog = true;
      this.neu = true;
		},
		hideDialog() {
			this.aktionDialog = false;
			this.submitted = false;
		},
    async saveAktionen(){
      this.submitted = true;
      if(this.neu){

          await this.dataService.saveAktionen(this.aktionObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Aktion erstellt', life: 3000});
          setTimeout(async()=> {this.aktionen = await this.dataService.getAktionen()}, 100);
          this.aktionDialog = false;
          this.aktionObj = {};

      } else {
          await this.dataService.updateAktionen(this.aktionObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Aktion aktualisiert', life: 3000});
          setTimeout(async()=> {this.aktionen = await this.dataService.getAktionen()}, 100);
          this.aktionDialog = false;
          this.aktionObj = {};
      }

    },
    editAktion(aktion) {
      this.aktionObj = {...aktion};
      this.aktionDialog = true;
      this.neu = false;
    },
    confirmDeleteAktion(aktion) {
      this.aktionObj = aktion;
      this.deleteAktionDialog = true;
    },
    async deleteAktionen() {
      let data = [];
      data.push(this.aktionObj);
      await this.dataService.deleteAktionen(data);

      this.deleteAktionDialog = false;
      this.aktionObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Aktion gelöscht', life: 3000});
      setTimeout(async()=> {this.aktionen = await this.dataService.getAktionen()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteAktionenDialog = true;
		},
    async deleteSelectedAktionen() {
      this.deleteAktionenDialog = false;
      await this.dataService.deleteAktionen(this.selectedAktion);
      this.selectedAktion = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Aktion gelöscht', life: 3000});
      setTimeout(async()=> {this.aktionen = await this.dataService.getAktionen()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
    searchGruppen(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredGruppen = [...this.gruppen];
        }
        else {
          this.filteredGruppen = this.gruppen.filter((gruppe) => {
            return gruppe.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 50);
    },
    searchAktion(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredAktionen = [...this.aktion];
        }
        else {
          this.filteredAktionen = this.aktion.filter((aktion) => {
            return aktion.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 50);
    },



  }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
